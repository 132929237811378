.card {
    &__title {
        margin: 0 0 0.75rem;
        font-size: 1.25rem;
        line-height: 1.4em;
        font-family: var(--font-montserrat);

        @media (min-width: 1280px) {
            font-size: 1.625rem;
            line-height: 1.231em;
        }
    }

    // @TODO: Сейчас отображение стоимости не используется. Пока не удаляю
    //&__price {
    //    font-size: 0.875rem;
    //    font-weight: 300;
    //    font-family: var(--font-montserrat);
    //
    //    @media (min-width: 1280px) {
    //        font-size: 1rem;
    //    }
    //}

    &__description {
        margin-top: 1.5rem;
        font-size: 0.875rem;
        line-height: 1.571em;
        font-weight: 300;

        @media (min-width: 1280px) {
            font-size: 1rem;
        }

        @media (max-height: 900px) {
            font-size: 0.875rem;
            line-height: 1.571em;
            margin-block-start: .5em;
            margin-block-end: .5em;
        }
    }

    &__links {
        padding: 0;
        margin-top: 2rem;
        font-size: 0.75rem;
        font-weight: 300;
        line-height: 1em;
        font-family: var(--font-montserrat);
        list-style: none;

        @media (min-width: 1280px) {
            font-size: 0.875rem;
        }

        li {
            margin-bottom: 0.75rem;
        }

        a {
            position: relative;
            text-decoration: none;
            border-bottom: 1px solid rgba(255, 255, 255, 0.5);
            transition: border-color 0.2s ease-out;

            &:hover {
                border-color: rgba(255, 255, 255, 0.8);
            }

            &::after {
                position: absolute;
                display: block;
                content: "";
                width: 14px;
                height: 14px;
                top: -2px;
                right: -16px;
                background: url("external-link-white.svg") center center no-repeat;
                background-size: contain;
            }
        }
    }
}
