.cards-list {
    display: flex;
    align-items: center;
    will-change: transform;

    &__wrapper {
        display: flex;
    }

    &__item {
        width: 100vw;
        padding: 0 2rem;
        box-sizing: border-box;

        @media (min-width: 768px) {
            width: var(--card-width-tablet);
        }
        @media (min-width: 1024px) {
            width: var(--card-width-desktop);
        }
    }
}
