$leftContentPosition: calc(100vw / 12 * 4 - 4rem);
$leftContentTitlePosition: calc(100vw / 12 * 4 - 6rem);
$sliderDefaultLeftPosition_desktop: calc(100vw / 12 * 4 - 6rem);
$sliderDefaultLeftPosition_tablet: calc(100vw / 12 * 4 - 6rem);

.screen {
    position: relative;
    min-height: 100vh;

    &::after {
        position: absolute;
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: url("../../images/bg-optimized.webp");
        background-size: cover;
        opacity: 0.7;
        z-index: 0;

        // background jpg, для бразуеров, которые не поддреживают webp
        body.no-webp & {
            background-image: url("../../images/bg-optimized.jpg");
        }
    }

    * {
        z-index: 1;
    }

    &_white {
        background-color: var(--color-white);

        &::before {
            background: var(--color-black);
        }
    }

    &_black {
        background-color: var(--color-black);

        &::before {
            background: var(--color-white);
        }

        &::after {
            filter: invert(100%);
        }
    }

    &__header {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        height: 80px;
        padding: 0 2rem 0 6rem;
        align-items: flex-start;
        z-index: 2;
        box-sizing: border-box;

        @media (min-width: 768px) {
            margin-left: $leftContentTitlePosition;
            width: var(--card-width-tablet);
        }
        @media (min-width: 1024px) {
            height: 140px;
            padding: 0 2rem;
            width: var(--card-width-desktop);
        }
    }

    &_slider {
        &::before {
            content: "";
            position: fixed;
            left: $leftContentPosition;
            margin-left: -2rem;
            width: var(--card-width-tablet);
            top: 0;
            bottom: 0;
            transform: translate(0, -100%);
            transition: transform 0.25s;
            display: none;
            z-index: 1;

            @media (min-width: 768px) {
                display: block;
            }
            @media (min-width: 1024px) {
                width: var(--card-width-desktop);
            }
        }

        &::after {
            background-attachment: fixed;
        }

        &_active {
            &::before {
                transform: translate(0, 0);
            }
        }
    }

    &_slider &__header {
        position: sticky;
        top: 0;
    }

    &__box {
        display: flex;
        flex-direction: column;
        z-index: 1;

        .screen_about & {
            height: 100vh;
            padding: 0 2rem;
            justify-content: center;

            @media (min-width: 1024px) {
                padding-left: $leftContentPosition;
            }
        }

        .screen_slider & {
            position: sticky;
            top: 0;
            justify-content: center;
            height: 100vh;
            margin-top: -80px;
            color: var(--color-white);
            mix-blend-mode: difference;
            box-sizing: border-box;
            overflow: hidden;
            margin-left: 0;
            padding-left: 50%;

            @media (min-width: 768px) {
                padding-left: var(--card-width-tablet);
                margin-left: $sliderDefaultLeftPosition_tablet;
            }
            @media (min-width: 1024px) {
                padding-left: var(--card-width-desktop);
                margin-left: $sliderDefaultLeftPosition_desktop;
                margin-top: -140px;

                @media (max-height: 900px) {
                    padding-top: 140px;
                    justify-content: normal;
                }
            }
        }

        .screen_content & {
            padding: 0 2rem 2rem;

            @media (min-width: 1024px) {
                padding: 0 4rem 0 $leftContentPosition;
            }
        }
    }
}
