@import "./fonts";

:root {
    --color-white: #fff;
    --color-black: #000;
    --color-cerulean: #00a7db;
    --color-electric-violet: #bd03d1;
    --color-persian-plum: #721c24;
    --color-parsley: #155724;
    --bg-gradient: linear-gradient(90deg, #00f7db 0%, #bd03d1 100%);
    --card-width-desktop: calc(100vw / 3);
    --card-width-tablet: calc(100vw / 2);

    /* @TODO: Установить запасной шрифт. */
    --font-roboto: 'Roboto', sans-serif;
    --font-montserrat: 'Montserrat', sans-serif;
}

html {
    color: var(--color-black);
    font-size: 16px;
    line-height: 1.375em;
    font-weight: 300;
    font-family: var(--font-roboto);
    -webkit-font-smoothing: antialiased;
}

body {
    padding: 0;
    margin: 0;
}

h1, h2, h3, h4, h5, h6 {
    margin: 2rem 0 1rem;
    font-weight: 400;
    font-family: var(--font-montserrat);
}

section {
    position: relative;

    @for $i from 0 through 10 {
        &:nth-child(#{$i}) {
            z-index: $i;
        }
    }
}
