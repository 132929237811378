@keyframes gradient-animation {
    0% {
        background-position: 0 2%;
    }

    50% {
        background-position: 100% 99%;
    }

    to {
        background-position: 0 2%;
    }
}

.logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;

    @media (min-width: 768px) {
        position: relative;
    }

    &__title {
        order: -1;
        padding: 0 2px;
        margin-bottom: 0.5rem;
        font-size: 3rem;
        line-height: 1em;
        font-weight: 700;
        font-family: var(--font-montserrat);
        text-transform: uppercase;
        background: linear-gradient(to right, var(--color-cerulean) 0%, var(--color-electric-violet) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-size: 200% 200%;
        animation: gradient-animation 4s ease infinite;

        @media (min-width: 480px) {
            font-size: 3.75rem;
        }
        @media (min-width: 768px) {
            font-size: 6.625rem;
            order: 1;
        }

        span {
            display: none;

            @media (min-width: 1280px) {
                display: inline;
            }
        }
    }

    &__subtitle {
        display: block;
        font-size: 0.875rem;
        line-height: 1.571em;
        font-weight: 300;
        font-family: var(--font-roboto);

        @media (min-width: 480px) {
            font-size: 1rem;
        }
        @media (min-width: 768px) {
            position: absolute;
            top: 0;
            left: 0;
            transform: translateY(-100%);
        }
        @media (min-width: 1280px) {
            font-size: 1.375rem;
            line-height: 1.455em;
            font-weight: 200;
        }
    }
}
