.navigation {
    position: fixed;
    font-size: 0.875em;
    line-height: 1em;
    font-weight: 300;
    font-family: var(--font-montserrat);
    left: 0;
    top: 0;
    z-index: 998;

    @media (max-width: 1023px) {
        max-width: 320px;
        width: 80vw;
        height: 100vh;
        transition: transform 0.4s ease-in-out;
        transform: translateX(-100%);
        background: rgba(000, 000, 000, 0.8);

        &_open {
            transform: translateX(0%);
        }
    }
    @media (max-width: 600px) {
        overflow-x: hidden;
    }
    @media (min-width: 1024px) {
        position: fixed;
        top: 2rem;
        left: 2rem;
        font-size: 1rem;

        /*
         * Слой наклдывается с другими цветами слоев через инверсию.
         * Если в дочерних элементах использовать inherit для цвета.
         */
        color: white;
        mix-blend-mode: difference;
    }

    &__wrapper {
        @media (max-width: 1023px) {
            padding: 6rem 0 0 2rem;
        }
        @media (max-width: 600px) {
            min-height: 350px;
            position: relative;
            height: 100vh;
            box-sizing: border-box;
        }
    }

    &__feedback {
        font-weight: 400;
        font-family: var(--font-montserrat);
        position: relative;
        padding-left: 30px;
        font-size: 1rem;
        outline: none;
        border: none;
        cursor: pointer;
        display: block;
        background: linear-gradient(to right, var(--color-cerulean) 0%, var(--color-electric-violet) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        &::before {
            content: "";
            position: absolute;
        }

        &_crm {
            margin-bottom: 16px;

            &::before {
                left: 0;
                top: -3px;
                bottom: -3px;
                width: 25px;
                background: url("../../images/call.svg") no-repeat center center;
                background-size: 100% auto;
            }
        }

        &_email {
            &::before {
                left: 3px;
                top: 0;
                bottom: 0;
                width: 22px;
                background: url("../../images/email.svg") no-repeat center center;
                background-size: 100% auto;
            }
        }
    }

    &__contacts {
        display: none;

        @media (max-width: 600px) {
            max-width: 320px;
            width: 80vw;
            display: block;
            position: absolute;
            left: 2rem;
            font-weight: 400;
            font-family: var(--font-montserrat);
            bottom: auto;
            top: calc(100% - 4rem - 33px);
            font-size: 0.875rem;
            color: #fff;
            z-index: 999;
        }
    }

    &__list {
        margin: 0;
        padding: 0;
    }

    &__item {
        margin-bottom: 1rem;
        list-style: none;
    }

    &__link {
        position: relative;
        color: var(--color-white);
        text-decoration: none;
        text-transform: lowercase;
        transition: color 0.2s ease-out;
        mix-blend-mode: difference;

        &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 1px;
            left: 0;
            bottom: 0;
            background: white;
            transform-origin: left;
            transform: scaleX(0) translateZ(0);
            transition: transform 0.2s ease-out;
        }

        &:hover::before, &.active::before {
            transform: scaleX(1) translateZ(0);
        }
    }
}
