.request-form {
    margin-top: 2rem;

    label {
        display: block;
        margin-bottom: 0.2rem;
    }

    &__row {
        margin-bottom: 1rem;
    }

    &__button {
        display: block;
        width: 100%;
        height: 3rem;
        margin-top: 1.5rem;
        font-weight: 500;
        font-family: var(--font-montserrat);
        text-transform: uppercase;
        box-sizing: border-box;
    }

    &__status {
        display: none;
    }

    &__status_error {
        color: var(--color-persian-plum);
    }

    &__status_success {
        color: var(--color-parsley);
    }

    &__status_show {
        display: block;
    }
}
