.typography {
    max-width: 640px;
    font-size: 0.875rem;
    line-height: 1.571rem;

    @media (min-width: 480px) {
        font-size: 1rem;
        line-height: 1.375em;
    }

    a {
        color: #000;
        text-decoration: underline;

        &:hover {
            @media (min-width: 901px) {
                text-decoration: none;
            }
        }
    }
}
