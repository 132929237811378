@keyframes gradient-animation {
    0% {
        background-position: 0 2%;
    }

    50% {
        background-position: 100% 99%;
    }

    to {
        background-position: 0 2%;
    }
}

.button {
    font-family: var(--font-roboto);
    cursor: pointer;

    &_gradient {
        color: #fff;
        background: linear-gradient(to right, var(--color-cerulean) 0%, var(--color-electric-violet) 100%);
        background-size: 150% 150%;
        border: none;

        &:hover {
            animation: gradient-animation 4s ease infinite;
        }
    }
}
