.contact {
    position: fixed;
    left: 2rem;
    bottom: 2rem;
    font-weight: 400;
    font-family: var(--font-montserrat);
    z-index: 999;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    transition: visibility 0s 0.4s;

    @media (max-width: 1024px) {
        position: absolute;
        bottom: auto;
        top: calc(100vh - 4rem);
    }

    &_email {
        padding-left: 30px;
        font-size: 1rem;
        background: linear-gradient(to right, var(--color-cerulean) 0%, var(--color-electric-violet) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        &::before {
            content: "";
            position: absolute;
            left: 3px;
            top: 0;
            bottom: 0;
            width: 22px;
            background: url("../../images/email.svg") no-repeat center center;
            background-size: 100% auto;
        }
    }

    &_call-popup {
        padding-left: 30px;
        transform: translateY(-150%);
        font-size: 1rem;
        background: linear-gradient(to right, var(--color-cerulean) 0%, var(--color-electric-violet) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: -3px;
            bottom: -3px;
            width: 25px;
            background: url("../../images/call.svg") no-repeat center center;
            background-size: 100% auto;
        }
    }
}

// @TODO: Отрефакторить кусок кода. Нарушение БЭМ и компонентной структуры.
@media (max-width: 600px) {
    .scrolling-disable {
        .contact {
            transition: visibility 0s;
            visibility: hidden;
        }
    }
}
