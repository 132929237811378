.tags {
    position: absolute;
    list-style: none;
    right: 2rem;
    bottom: 2rem;
    margin: 0 0 0 -0.25rem;
    font-size: 0;
    line-height: 0;

    @media (max-width: 1023px) {
        position: absolute;
        padding-bottom: 2rem;
        left: 30%;
        right: 2rem;
        bottom: 0;

        br {
            display: none;
        }
    }
    @media (max-width: 767px) {
        display: none;
    }

    &__item {
        margin: 0.25rem;
        display: inline-block;
        vertical-align: top;
    }

    &__link {
        position: relative;
        color: var(--color-black);
        text-decoration: none;
        font-size: 0.875rem;
        line-height: 17px;
        font-weight: 400;
        font-family: var(--font-montserrat);
        display: block;
        box-sizing: border-box;
        padding: 0.5rem;
        border-left: 1px solid #00f7db;
        border-right: 1px solid #bd03d1;
        transition: border-color 0.25s, background-color 0.25s, color 0.25s;

        &::before,
        &::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            height: 1px;
            background: var(--bg-gradient);
        }

        &::before {
            top: 0;
        }

        &::after {
            bottom: 0;
        }

        span {
            &::before {
                content: "#";
            }
        }
    }
}
