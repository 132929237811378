.textarea {
    display: block;
    width: 100%;
    height: 6rem;
    padding: 0.5rem;
    font-size: 1rem;
    font-family: var(--font-roboto);
    border: 1px solid #ccc;
    box-sizing: border-box;
    resize: vertical;
}
