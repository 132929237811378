.input {
    display: block;
    width: 100%;
    height: 3rem;
    padding: 0 0.5rem;
    font-size: 1rem;
    font-family: var(--font-roboto);
    border: 1px solid #ccc;
    box-sizing: border-box;

    &::placeholder {
        color: #ccc;
        font-size: 1rem;
        font-family: var(--font-roboto);
    }
}
