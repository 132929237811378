.crm-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    overflow-x: hidden;
    z-index: 1000;
    visibility: hidden;
    opacity: 0;
    transform: translate(-100%, 0);
    backface-visibility: hidden;
    transition: visibility 0s 0.5s, transform 0s 0.5s, opacity 0s 0.5s;

    &::before {
        content: '';
        position: fixed;
        left: 400px;
        top: 17px;
        width: 40px;
        height: 40px;
        z-index: 2;
        background: svg-load("../../images/close.svg", fill=#fff) no-repeat center;
        background-size: 14px auto;
        transition: transform 0.25s, opacity 0s;
        backface-visibility: hidden;
        opacity: 0;

        &:hover {
            transform: scale(1.1);
        }

        @media (max-width: 500px) {
            display: none;
        }
    }

    &__wrapper {
        position: relative;
        width: 400px;
        background: var(--color-white) url("../../images/bg-optimized.jpg");
        padding: 30px;
        min-height: 100%;
        box-sizing: border-box;
        transform: translate(-100%, 0);
        transition: transform 0.5s;

        .crm-popup_open & {
            transform: translate(0, 0);
        }

        body.webp & {
            background: var(--color-white) url("../../images/bg-optimized.webp");
        }

        @media (max-width: 500px) {
            width: 100%;
        }

        iframe {
            margin: 0 !important;
        }
    }

    &_open {
        visibility: visible;
        opacity: 1;
        transform: translate(0);
        transition: visibility 0s, transform 0s, opacity 0s;

        &::before {
            opacity: 1;
            transition: transform 0.25s, opacity 0s 0.5s;
        }
    }

    &__close {
        position: absolute;
        right: 17px;
        top: 17px;
        width: 40px;
        height: 40px;
        z-index: 2;
        background: svg-load("../../images/close.svg", fill=#000) no-repeat center;
        background-size: 14px auto;
        transition: transform 0.25s;
        backface-visibility: hidden;
        display: none;

        @media (max-width: 500px) {
            display: block;
        }
    }

    &__title {
        font-family: var(--font-montserrat);
        font-weight: 700;
        text-transform: uppercase;
        background: linear-gradient(to right, var(--color-cerulean) 0%, var(--color-electric-violet) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-block;
    }
}
