.screen-header {
    margin: 0;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 300;
    text-transform: uppercase;
    font-family: var(--font-montserrat);
    background: linear-gradient(to right, var(--color-cerulean) 0%, var(--color-electric-violet) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (min-width: 1024px) {
        font-size: 1.125rem;
        line-height: 1.5rem;
    }
}
