.begin-steps {
    padding-bottom: 0.5rem;
    color: var(--color-black);
    height: calc(100% - 2rem);
    display: flex;
    align-items: center;
    box-sizing: border-box;

    @media (min-width: 768px) {
        padding-bottom: 4rem;
    }

    h1, h2, h3, h4, p {
        margin: 0;
        font-family: var(--font-roboto);
    }

    h1, h2, h3, h4 {
        font-size: 1.125em;
        margin-bottom: 1.5rem;
        text-transform: uppercase;
        font-family: var(--font-montserrat);
    }

    p {
        margin-bottom: 1rem;
    }

    &__grid {
        display: grid;
        grid-template-rows: auto 1fr;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 24px;
        grid-row-gap: 24px;

        @media (max-width: 600px) {
            display: block;
        }
    }

    &__item {
        background: var(--bg-gradient);
        padding: 4px;
        box-sizing: border-box;

        @media (max-width: 600px) {
            margin-bottom: 2rem;
        }

        &-wrapper {
            height: 100%;
            padding: 20px;
            background-color: var(--color-white);
            box-sizing: border-box;
        }

        &:last-child {
            .begin-steps__item-wrapper {
                background: rgba(0,0,0, .2);
                color: #fff;

                a {
                    color: #fff;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    &__contacts {
        @media (min-width: 480px) {
            display: flex;
            align-items: center;
        }
    }

    &__email,
    &__crm-popup {
        display: inline-block;
        position: relative;
        padding-left: 26px;

        &::before {
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -10px;
        }
    }

    &__email {
        margin-bottom: 0.5rem;

        @media (min-width: 480px) {
            margin-bottom: 0;
            margin-right: 1rem;
        }

        &::before {
            background: url("./email.svg") center center no-repeat;
        }
    }

    &__crm-popup {
        color: var(--color-white);
        font-size: 1rem;
        font-weight: 300;
        font-family: var(--font-roboto);
        text-decoration: underline;
        background-color: transparent;
        border: none;
        white-space: nowrap;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }

        &::before {
            background: url("./call-white.svg") center center no-repeat;
            background-size: 20px auto;
        }
    }
}
